import{expConvert} from '@/plugin/compressor';
import { createRouter, createWebHashHistory } from "vue-router";
const routerOption = {
    history: createWebHashHistory(),
    routes:[{
        path: "/",
        name: "initial",
        component: () => import('@/views/initial.vue'),
    }]
};
/* 加载views下的路由 会收集该文件夹下的index.vue文件进行路由收集如：/manages/list/index.vue => path: /manages/list */
(() => {
      const requireComponent:any = require.context('@/views',true, /index.(vue)|Index.(vue)$/);
      requireComponent.keys().forEach((fileName: string) => {
          const componentName = expConvert(fileName.replace('./', '').replace(/\.\w+$/, '').substring(0,fileName.lastIndexOf('/') - 2));
          routerOption.routes.push( {
              path: `/${componentName}`,
              name: componentName,
              component: () => import(`@/views/${componentName}/index.vue`),
          })
    });
})();
export default createRouter(routerOption);
