import { expConvert } from './compressor';
import { resolveComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
const registerComponent = {
    install: (Vue: any): void => {
        registerComponent.registerComponent(Vue); //自定义组件
        registerComponent.elementIcons(Vue); // element plus 图标库
    },
    registerComponent: (Vue: any): void => {
        const component: any = [];
        const requireComponent: any = require.context(
            '../components',
            true,
            /index.(vue)|Index.(vue)$/
        );
        requireComponent.keys().forEach((fileName: string) => {
            const componentConfig = requireComponent(fileName);
            const componentName = expConvert(
                fileName
                    .replace('/index.vue', '')
                    .replace('/Index.vue', '')
                    .replace(/\.\w+$/, '')
                    .split('/')
                    .pop() + ''
            );
            component.push({
                component: componentName,
                url: fileName,
                comment: componentConfig.default.name,
            });
            Vue.component(componentName, componentConfig.default || componentConfig);
        });
    },
    elementIcons: (Vue: any): void => {
        for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
            Vue.component(key, component);
        }
    },
};
export default registerComponent;
