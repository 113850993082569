<template>
    <div class="pentester">
        <div style="margin: 10px 20px">
            <el-input v-model="textarea" style="width: 100%" :rows="20" type="textarea" />
            <footer>
                <el-button type="primary" style="width: 100%; height: 56px" @click="onSubmit"
                    >Submit</el-button
                >
            </footer>
        </div>
        <div class="upload">
            <el-upload
                v-model:file-list="fileList"
                :auto-upload="false"
                :on-change="onUploadChange"
            >
                <el-button type="primary">选择上传txt 文件</el-button>
            </el-upload>
        </div>
    </div>
</template>
<script setup>
import { toRefs, reactive } from 'vue';
import Index from './typescript/index';
import payable from '../market/payable/index.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
const {
    user,
    form,
    onSubmit,
    onSearch,
    search,
    textarea,
    isInsert,
    isRecharge,
    list,
    menus,
    onMove,
    onOpen,
    avatar,
    fileList,
    isOpenQA,
    onSkipped,
    option,
    isLogin,
    vercode,
    onUploadChange,
    onChangeCode,
    onSignup,
    onSignin,
    reminder,
    openTitle,
    resetReminder,
    dynamic,
    isAside,
    onConfirmqa,
    isLoginLoding,
} = toRefs(reactive(new Index()));
</script>
<style lang="less">
@import url('../../common/css/public.less');
.pentester {
    height: 100%;
    --bg-color: rgba(33, 37, 41);
    background-color: #0d0d0d;

    color: #ffffff;
    .nav {
        height: 40px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;
        .r {
            justify-content: right;
            .li {
                padding: 0 12px;
                &:not(:last-child) {
                    border-right: 1px solid #ccc;
                }
            }
            .money {
                color: red;
            }
            .money:before {
                content: '$';
            }
            .avatars {
                width: 28px;
                height: 28px;
                padding-right: 0;
            }
            .qa {
                text-decoration: underline;
            }
        }
        &:hover {
            color: var(--bg);
        }
    }
    .header {
        .search {
            padding: 16px 60px;
            background-color: rgba(33, 37, 41);
        }
        .logo {
            font-size: 37px;
            text-align: left;
            padding: 16px 0px 24px 0px;
        }
        .input {
            flex: 1;
        }
        .number {
            min-width: 100px;
        }
        .operate {
            > .el-select {
                width: 160px;
            }
            > .bnt {
                --el-color-primary: #ffca2c;
                --el-button-hover-bg-color: #ffc107;
                --el-button-hover-border-color: #ffc107;
                --el-color-primary-dark-2: #ffc107;
                height: 40px;
            }
            .gap {
                height: 40px;
                margin-right: 10px;
            }
        }
        .remark {
            margin-top: 20px;
            color: red;
            text-align: left;
        }
    }
    .main {
        padding: 20px 60px;
        .container {
            border-radius: 8px;
            background-color: rgba(33, 37, 41);
            .decorate {
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .left {
                    width: 38%;
                    padding: 10px;
                }
                .right {
                    flex: 1;
                    text-align: left;
                    h1 {
                        font-weight: 500;
                        margin-bottom: 1.5rem !important;
                        font-size: calc(1.375rem + 1.5vw);
                    }
                    h5 {
                        font-size: 20px;
                        margin-bottom: 1rem !important;
                    }
                }
                .router {
                    color: #ffa500;
                    text-decoration: none;
                    transition: color 0.3s ease-in-out;
                }
            }
        }
    }
    .login {
        color: #ffffff;
        --el-dialog-bg-color: rgba(33, 37, 41);
        .el-dialog__title {
            color: #ffffff;
        }
    }
    .el-dialog__body,
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .logo,
    .el-form-item__label {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
        --el-dialog-bg-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .el-form-item__label {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card {
        border: 1px solid gray;
    }
    .el-tabs--border-card > .el-tabs__header {
        border-bottom: 1px solid rgba(33, 37, 41);
    }

    .el-tabs__item:hover,
    .el-step__title {
        color: #ffffff;
    }
    .is-finish {
        color: var(--el-color-primary) !important;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-step__icon {
        background: var(--bg-color);
    }
}
</style>
