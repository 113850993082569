import { createStore } from "vuex";
const state = {
    formData: {
        elements:[]
    }
};
const mutations = {
    setFormElements: (state: any, data:any) => {
        state.formData.elements = data;
    }
};
const actions = {
    setFormElements: (context: any, data: any) => {
        context.commit('setFormElements', data);
    }
};
const getters = {
    getFormElements: (state:any) => {
        return state.formData.elements;
    }
};

const modules = {

};
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules,
});