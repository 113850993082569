<template>
    <div class="possport">possport</div>
</template>
<script setup>
import Index from './typescript/index';
import { ref, reactive, toRefs, onMounted } from 'vue';
import { Edit, Goods, CircleCheck, ArrowRight } from '@element-plus/icons-vue';
const { list } = toRefs(reactive(new Index()));
</script>
<style lang="less" scoped>
.payable {
    width: 100%;
    height: 100%;
    overflow: scroll;
}
</style>
