<template>
    <div class="flow">
        <div id="container"/>
    </div>
</template>
<script lang="ts" setup>
    import Index from './typescript/index';
    import {onMounted,onUnmounted} from 'vue';
    let graph:any;
    onMounted(() => {graph = new Index()});
    onUnmounted(() => {delete graph.graph});
</script>
<style lang="less" scoped>
.flow{
    width: 100vw;
    height: 100vw;
    .container{
        width: 100vw;
        height: 100vw;
    }
}
</style>