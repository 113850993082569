<script>
    import {h,resolveComponent,inject,provide} from 'vue';
    import {dynamicCompressor} from '@/plugin/compressor';
    export default {
        props:{
            data:{
                type:Object,
                default: () => { return {}}
            }
        },
        setup(props) {
            console.log(props.data);
            if('childs' in props.data){
                return () => h(resolveComponent("xdraggable"),{data:props.data});
            } else {
                return () => h(resolveComponent(props.data.name || props.data.type),{data:props.data});
            }
        }
    }
</script>