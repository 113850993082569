import {ref,reactive,computed} from 'vue';
import Base from "@/common/js/base";
export default class index extends Base{
    private messages = reactive<Array<string>>([]);
    constructor(data:any,context:any){
        super();
        this.messages.push(...data);
        this.context = context;
    }
    public onClose = () => {
        this.context('onClose');
    }
    public onClick = () => {
        this.context('onClick',this.messages);
    }
}