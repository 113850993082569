import { ElMessage, ElLoading } from 'element-plus';
import { onMounted, reactive, ref } from 'vue';
import Page from '@/common/js/page';
import dayjs from 'dayjs';
import { copyElementText } from '@/common/js/tools';
export default class Index extends Page {
    private form: any = reactive({
        birthday: '',
        city: '',
        zip: '',
        state: '',
    });
    private urls: any = {
        0: '/ssn/orderList',
        1: '/ssn/meOrder',
    };
    private expmax: any = ref(1);
    private expForm: any = reactive({
        code: '',
        size: 5000,
        page: 1,
    });
    private isBatchExport: any = ref(false);
    private tabIndex: string = '0';
    private el: any;
    private total: any = ref(1);
    private options: any = reactive([]);
    private isSearch: any = ref(false);
    private states: any = reactive([]);
    constructor() {
        super();
        this.queryState();
        this.onChangeCode();
        onMounted(() => {
            this.queryDate();
            this.el = document.getElementById('container');
        });
    }
    private onTabChange = (tab: any) => {
        this.onReset();
        this.tabIndex = tab;
        this.queryDate();
    };
    private queryState() {
        this.states.splice(0, this.states.length);
        this.post('/ssn/qstate', { data: { city: 1 } })
            .then((data: any) => {
                if (data.data) {
                    data.data.forEach((each: any) => {
                        this.states.push(each);
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    public onSearch = () => {
        this.isSearch.value = false;
        this.queryDate();
    };
    public onReset = () => {
        this.pageSize.value = 1;
        Object.keys(this.form).forEach((key: string) => {
            this.form[key] = '';
        });
    };
    private queryDate() {
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        this.list.splice(0, this.list.length);
        const option: any = { ...this.form };
        option.birthday = this.form.birthday ? dayjs(this.form.birthday).format('DD/MM/YYYY') : '';
        this.post(this.urls[this.tabIndex], {
            data: Object.assign({ curPage: this.pageSize.value }, option),
        })
            .then((data: any) => {
                if (+this.tabIndex) {
                    this.total.value = data.data.list.length ? data.data.list[0].total : 0;
                    this.list.push(...data.data.list);
                } else {
                    this.total.value = data.data.orderList.length
                        ? data.data.orderList[0].total
                        : 0;
                    this.list.push(...data.data.orderList);
                    this.mitter.emit('listeneruser', data.data.userInfo);
                }
                this.expmax.value = Math.ceil(
                    !this.total.value ? 1 : this.total.value / this.expForm.size
                );
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'error' });
            })
            .finally(() => {
                this.loading.close();
            });
    }
    public onShopping = (row: any, index: number) => {
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        let option = { order_id: row.order_id, order_price: row.order_price, buy_type: '1' };
        this.post('/ssn/buyssn', { data: option })
            .then((data: any) => {
                this.list.splice(index, 1);
                ElMessage({ message: data.message, type: 'success' });
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'error' });
            })
            .finally(() => {
                this.loading.close();
            });
    };
    public onBatch = () => {
        const selection = this.options.map((m: any) => m.order_id);
        if (!selection.length) {
            ElMessage({ message: 'Order cannot be empty', type: 'warning' });
            return;
        }
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        let option = { order_id: selection.join(','), buy_type: '1' };
        this.post('/ssn/bb', { data: option })
            .then((data: any) => {
                if (data.code === '1000') {
                    const timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        this.queryDate();
                    }, 1000);
                }
                ElMessage({
                    message: data.message,
                    type: data.code === '1000' ? 'success' : 'warning',
                });
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'warning' });
            })
            .finally(() => {
                this.loading.close();
            });
    };
    public onSelected = (data: any) => {
        this.options.splice(0, this.options.length);
        this.options.push(...data);
    };
    public onChange = () => {
        this.queryDate();
    };
    public onExport = (row: any) => {
        copyElementText(row.order_first_name);
    };
    public onBatchExport = () => {
        if (!this.expForm.code) {
            ElMessage({ message: 'code cannot be empty', type: 'warning' });
            return;
        }
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        this.post('/ssn/export', {
            responseType: 'blob',
            timeout: 120 * 1000,
            data: { code: this.expForm.code, size: this.expForm.size, page: this.expForm.page },
        })
            .then((data: any) => {
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute('download', `${new Date().getTime()}.txt`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'warning' });
                this.onChangeCode();
            })
            .finally(() => {
                this.loading.close();
                this.isBatchExport.value = false;
            });
    };
    public onChangeCode = () => {
        this.vercode.value = `/ssn/verification?t=${new Date().getTime()}`;
    };
}
