<template>
    <div class="tools">
        <el-collapse v-model="names">
            <el-collapse-item v-for="item in list" :name="item.name" :key="item.name">
                <template #title>
                    <span class="title">{{item.title}}</span>
                </template>
                <div class="container">
                    <draggable v-model="item.list" force-fillback="true" :group="group" :move="onMove" @end="onEnd" class="list-group" item-key="tools">
                        <template #item="{element,index}" >
                            <div class="box" :key="index" @click="onHitBox(element,item.name)">
                                {{element.title}}
                            </div>
                        </template>
                    </draggable>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script lang="ts" setup>
    import draggable from 'vuedraggable';
    import { reactive} from 'vue';
    import Tools from '../typescript/tools';
    const {names,list,group,onHitBox,onMove,onEnd} = reactive(new Tools());
</script>
<style lang="less" scoped>
    .tools{
        overflow:hidden;
        .title{
            padding-left:10px;
            font-weight: bold;
        }
        .container{
            .list-group{
                display:grid;
                gap:10px 10px;
                padding-left:10px;
                padding-right: 10px;
                grid-template-columns:49% 49%;
                .box{
                    padding:4px 6px;
                    overflow: hidden;
                    border-radius:4px;
                    border:1px dashed #ccc;
                    white-space: nowrap;
                    border:1px dashed #ccc;
                    text-overflow: ellipsis;
                    &:hover{
                        color: #4ea6ed;
                        cursor: move;
                        border-color: #4ea6ed;
                    }
                }
            }
        }
    }
</style>