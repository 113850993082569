<template>
    <div class="xyorder">
        <nav class="nav">
            <span class="back" @click="onBack">Back</span>
            <span>Order</span>
        </nav>
        <main class="main" :style="{ '--padding': padding, '--height': height }">
            <el-icon class="divtion" v-if="isLoadsh"><Refresh /></el-icon>
            <div v-infinite-scroll="queryList" class="infinite-list">
                <el-card class="card" v-for="item in list" :key="item.ID" shadow="hover">
                    <el-descriptions>
                        <template #title>
                            <div class="outline">
                                <div class="left">
                                    <span class="userInfo" v-html="computeAvatar" />
                                    <span>User Info</span>
                                </div>
                                <div class="right" @click="onCopy(item)">Copy</div>
                            </div>
                        </template>
                        <el-descriptions-item
                            v-for="key in columns"
                            v-show="!ignore.includes(key)"
                            :key="key"
                        >
                            <template #label>
                                <span>{{ key }}：</span>
                            </template>
                            <span class="value">{{ item[key] || '-' }}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </div>
        </main>
    </div>
</template>
<script setup>
import { toRefs, reactive } from 'vue';
import Order from '../typescript/order';
const {
    list,
    onBack,
    padding,
    columns,
    onCopy,
    ignore,
    computeAvatar,
    queryList,
    height,
    isLoadsh,
} = toRefs(reactive(new Order()));
</script>
<style lang="less">
.xyorder {
    color: #fff;
    height: 100%;
    --bg-color: rgba(33, 37, 41);
    background-color: #0d0d0d;
    .main {
        padding: var(--padding);
    }
    .nav {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: rgba(33, 37, 41);
        .back {
            top: 20px;
            left: 20px;
            position: absolute;
            text-decoration: underline;
        }
    }
    .infinite-list {
        margin: 0;
        padding: 10px;
        height: var(--height);
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .el-dialog__body,
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .logo,
    .el-form-item__label {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
        --el-dialog-bg-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .el-form-item__label,
    .el-descriptions__table {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card {
        border: 1px solid gray;
    }
    .el-tabs--border-card > .el-tabs__header {
        border-bottom: 1px solid rgba(33, 37, 41);
    }

    .el-tabs__item:hover,
    .el-step__title {
        color: #ffffff;
    }
    .is-finish {
        color: var(--el-color-primary) !important;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-step__icon {
        background: var(--bg-color);
    }
    .consequence {
        background-color: #0d0d0d;
        // --el-box-shadow-light: 0px 0px 20px gray;
    }
    @keyframes divnim {
        from {
            transform: scale(1);
            transform: rotate(0deg);
        }
        to {
            transform: scale(1.01);
            transform: rotate(360deg);
        }
    }
    .divtion {
        font-size: 24px;
        animation: divnim 1s linear infinite;
    }
    .card {
        width: 100%;
        margin-top: 16px;
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .is-hover-shadow {
        &:hover {
            transform: scale(01.01);
        }
    }
    .outline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right {
            color: red;
            padding-right: 10px;
            text-decoration: underline;
        }
        .left {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .userInfo {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        display: inline-block;
    }
    .see {
        color: red;
        font-size: 12px;
        margin-left: 8px;
        text-decoration: underline;
        .el-icon {
            margin-left: 4px;
            font-size: 16px;
        }
    }
    .el-descriptions__title,
    .el-descriptions__label,
    .el-descriptions__content {
        .value {
            // vertical-align: sub;
        }
        color: #ffffff;
    }
    .el-descriptions__label {
        font-weight: 700;
        font-size: 16px;
    }
    .el-descriptions__title {
        width: 100%;
    }
}
</style>
