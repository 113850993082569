<template>
    <div class="rules">
        <el-collapse v-model="names">
            <el-collapse-item v-for="key in Object.keys(rules)" :name="key" :key="key">
                <template #title>
                    <span class="title">{{rules[key].title}}</span>
                </template>
                <div class="container">
                   {{rules[key].text}}
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script lang="ts" setup>
import{toRefs,reactive,} from 'vue';
import Rules from '../typescript/rules';
const {rules,names} = toRefs(reactive(new Rules()))
</script>
<style lang="less" scoped>
.rules{
    .el-collapse{
        padding-left:10px;
        .title{
            font-weight: bold;
        }
    }
}
</style>