/**
 * 前端各种分页继承，参数封装
 */
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref,reactive, inject,Ref} from 'vue';
//import { get, post } from '@/common/js/common';
export default class Base {
    protected store: any; // 全局状态管理
    protected router: any; // 路由
    protected context: any; // 子父节点调用上下文对象
    protected list:any = reactive([]); // 列表参数
    protected themeColor:Ref<string> = ref('');
    protected mitter: any = inject('mitter'); // 引入全局的mitt通信
    constructor() {
        this.store = useStore();
        this.router = useRouter();
        this.themeColor.value = this.store.state.themeColor;
    }
    get = (url: string, option: any) => {
        return new Promise((resolve, reject) => {
            //get(url, option).then(resolve).catch(reject);
        });
    };
    post = (url: string, option: any) => {
        return new Promise((resolve, reject) => {
            //post(url, option).then(resolve).catch(reject);
        });
    };
}
