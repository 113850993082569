<template>
    <div class="manage layout">
        <el-header class="nav">
        </el-header>
        <el-container class="container">
            <el-header class="head ba">
                <el-button type="danger" @click="onOpen">图片弹框</el-button>
            </el-header>
            <el-container class="main">
                <el-aside class="bl">
                    <tools/>   
                </el-aside>
                <el-main class="bl br">
                    <views/>
                </el-main>
                <el-aside class="br">
                    <rules/>
                </el-aside>
            </el-container>
            <el-footer class="footer ba"></el-footer>
        </el-container>
    </div>
</template>
<script setup>
    import {toRefs,reactive} from 'vue';
    import Index from './typescript/index';
    import views from './component/views.vue';
    import tools from './component/tools.vue';
    import rules from './component/rules.vue';
    const {list,onMove,onOpen} = toRefs(reactive(new Index()))
</script>
<style scoped lang="less">
@import url("../../common/css/public.less");
    .manage{
        height: 100%;
    }
</style>