<template>
    <div class="notification boxes">
        <div class="inner boxes">
            <div class="trumpeter">
                <font-awesome-icon class="icon" icon="bullhorn" />
            </div>
            <div class="message flex" @click="onClick">
                <el-carousel height="40px" trigger="click">
                    <el-carousel-item v-for="(msg, index) in messages" :key="index">
                        <div v-html="msg" class="roll" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="close" @click="onClose" title="Close">
                <el-icon><Close /></el-icon>
            </div>
        </div>
    </div>
</template>
<script setup>
import { toRefs, reactive } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Index from './typescript/index';
const props = defineProps(['data']);
const context = defineEmits(['onClose', 'onClick']);
const { messages, onClose, onClick } = toRefs(reactive(new Index(props.data, context)));
</script>
<style lang="less" scoped>
.notification {
    width: 100%;
    height: 40px;
    color: #ed6a0c;
    font-size: 16px;
    background: #fffbe8;
    position: relative;
    .inner {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        .trumpeter {
            width: 30px;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            background: #fffbe8;
            box-shadow: var(--el-box-shadow-light);
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
        .message {
            height: 40px;
            overflow: hidden;
            .roll {
                width: 100%;
                text-align: center;
                line-height: 40px;
            }
        }
        .close {
            width: 20px;
            padding-right: 10px;
        }
        .icon {
            --c: gray;
            color: var(--c);
            animation: roll 3s linear infinite alternate;
        }
        @keyframes roll {
            0% {
                --c: gray;
            }
            50% {
                --c: #ed6a0c;
            }
            100% {
                --c: red;
            }
        }
    }
}
</style>
