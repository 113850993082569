import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 路由
import store from './store'; // vuex 状态管理
import mitter from 'mitt'; // mitt 通信
import '@/common/css/base.less'; // 基础公用样式
import '@/common/css/reset.less'; // html 标签重置样式
import Storage from 'vue-lsp';
import ElementPlus from 'element-plus'; // element 组件
import 'element-plus/dist/index.css'; // element css
import 'animate.css/animate.min.css'; // animate 动画库
import registerComponent from '@/plugin/component';
import defineDirective from '@/plugin/directive';
import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import solid from '@fortawesome/fontawesome-free-solid';
import regular from '@fortawesome/fontawesome-free-regular';
import brands from '@fortawesome/fontawesome-free-brands';
fontawesome.library.add(solid);
fontawesome.library.add(regular);
fontawesome.library.add(brands);

const app = createApp(App);
app.use(Storage, {
    namespace: 'flow__', // key prefix
    name: 'ls', // name variable [ls] or [$ls],
    storage: 'local', // storage name session, local, memory
});
app.provide('mitter', mitter())
    .use(registerComponent)
    .use(defineDirective)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app');
