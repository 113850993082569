/* 左边配置组件菜单 */
export const tools:any = {
    'base':{
        name:'base',
        icon:'',
        title:'基础组件',
        list:[
            {name:'A0001',title:'单行文本',icon:'',desabled:false,mate:''},
            {name:'A0002',title:'多行文本',icon:'',desabled:false,mate:''},
            {name:'A0003',title:'数字框',icon:'',desabled:false,mate:''},
            {name:'A0004',title:'日期时间',icon:'',desabled:false,mate:''},
            {name:'A0005',title:'时间',icon:'',desabled:false,mate:''},
            {name:'A0006',title:'单选框',icon:'',desabled:false,mate:''},
            {name:'A0007',title:'多选框',icon:'',desabled:false,mate:''},
            {name:'A0008',title:'下拉框',icon:'',desabled:false,mate:''},
            {name:'A0009',title:'层级选择',icon:'',desabled:false,mate:''},
            {name:'A0010',title:'选择列表',icon:'',desabled:false,mate:''},
            {name:'A0011',title:'关联表单',icon:'',desabled:false,mate:''},
            {name:'A0012',title:'列表自定义',icon:'',desabled:false,mate:''},
            {name:'A0013',title:'数据列表',icon:'',desabled:false,mate:''},
            {name:'A0014',title:'关联工单',icon:'',desabled:false,mate:''},
            {name:'A0015',title:'图片上传',icon:'',desabled:false,mate:''},
            {name:'A0016',title:'文件上传',icon:'',desabled:false,mate:''},
            {name:'A0017',title:'地址',icon:'',desabled:false,mate:''},
            {name:'A0018',title:'定位',icon:'',desabled:false,mate:''},
            {name:'A0019',title:'富文本',icon:'',desabled:false,mate:''},
            {name:'A0020',title:'评分',icon:'',desabled:false,mate:''},
            {name:'A0021',title:'签名',icon:'',desabled:false,mate:''},
            {name:'A0022',title:'开关',icon:'',desabled:false,mate:''},
            {name:'A0023',title:'标签',icon:'',desabled:false,mate:''},
            {name:'A0024',title:'按钮组',icon:'',desabled:false,mate:''},
            {name:'A0025',title:'人脸识别',icon:'',desabled:false,mate:''},
            {name:'A0026',title:'语音',icon:'',desabled:false,mate:''},
            {name:'A0027',title:'自定义页面',icon:'',desabled:false,mate:''},
        ]
    },
    'business':{
        name:'business',
        icon:'',
        title:'业务组件',
        list:[
            {name:'',title:'关联资产',icon:'',desabled:false,mate:''},
            {name:'',title:'维保资产',icon:'',desabled:false,mate:''},
            {name:'',title:'关联测点',icon:'',desabled:false,mate:''},
            {name:'',title:'搜索推荐',icon:'',desabled:false,mate:''},
            {name:'',title:'维保任务',icon:'',desabled:false,mate:''},
            {name:'',title:'维保结果',icon:'',desabled:false,mate:''},
            {name:'',title:'流程节点处理时间',icon:'',desabled:false,mate:''},
            {name:'',title:'维保项目',icon:'',desabled:false,mate:''},
            {name:'',title:'计时器',icon:'',desabled:false,mate:''},
            {name:'',title:'自定义选项',icon:'',desabled:false,mate:''},
            {name:'',title:'导入列表',icon:'',desabled:false,mate:''},
            {name:'',title:'年历',icon:'',desabled:false,mate:''},
            {name:'',title:'日历',icon:'',desabled:false,mate:''},
            {name:'',title:'工作台',icon:'',desabled:false,mate:''},
            {name:'',title:'任务执行',icon:'',desabled:false,mate:''},
            {name:'',title:'课程课件',icon:'',desabled:false,mate:''},
            {name:'',title:'试卷',icon:'',desabled:false,mate:''},
        ]
    },
    'layout':{
        title:'布局组件',
        icon:'',
        name:'layout',
        list:[
            {name:'',title:'子表',icon:'',desabled:false,mate:''},
            {name:'',title:'面板',icon:'',desabled:false,mate:''},
            {name:'',title:'块儿',icon:'',desabled:false,mate:''},
            {name:'',title:'标签页',icon:'',desabled:false,mate:''},
        ]
    },
    'statistical':{
        title:'统计组件',
        icon:'',
        name:'statistical',
        list:[
            {name:'',title:'折线图',icon:'',desabled:false,mate:''},
            {name:'',title:'柱状图',icon:'',desabled:false,mate:''},
            {name:'',title:'堆叠图',icon:'',desabled:false,mate:''},
            {name:'',title:'饼图',icon:'',desabled:false,mate:''},
        ]
    },
    'system':{
        title:'系统组件',
        icon:'',
        name:'system',
        list:[
            {name:'',title:'选择人员',icon:'',desabled:false,mate:''},
            {name:'',title:'选择部门',icon:'',desabled:false,mate:''},
            {name:'',title:'选择区域',icon:'',desabled:false,mate:''},
            {name:'',title:'创建人部门',icon:'',desabled:false,mate:''},
            {name:'',title:'创建人组织',icon:'',desabled:false,mate:''},
            {name:'',title:'节点操作',icon:'',desabled:false,mate:''},
        ]
    },
    'slm':{
        title:'SLM组件',
        icon:'',
        name:'slm',
        list:[
            {name:'',title:'SLA级别',icon:'',desabled:false,mate:''},
            {name:'',title:'OLA级别',icon:'',desabled:false,mate:''},
            {name:'',title:'SLA是否超时',icon:'',desabled:false,mate:''},
            {name:'',title:'OLA是否超时',icon:'',desabled:false,mate:''},
            {name:'',title:'SLA剩余时间',icon:'',desabled:false,mate:''},
            {name:'',title:'OLA剩余时间',icon:'',desabled:false,mate:''},
        ]
    }
};