const name = ['login'];
const defineDirective: any = {
    install: (Vue: any): void => {
        for (let i = 0; i < name.length; i++) {
            Vue.directive(name[i], defineDirective[name[i]]);
        }
    },
    login: (el: any) => {
        el.style.display = 'none';
    },
};
export default defineDirective;
