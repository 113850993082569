import { resolveComponent, h } from 'vue';
export function expConvert(str: string) {
    // 去除首尾的空格、横线、下划线
    if (str) {
        return `${str.replace(/^[\_\-\s]*|[\_\-\s]*$/g, '').replace(/[\-|\_|\s]+(\w)/g, (substr, $1) => $1.toUpperCase())}`;
    } else {
        return 'str is null';
    }
}
/**
 * 
 * @param components 动态组件数据
 * @param compressor 初始化传入的空数组[] 用来h函数存储用的
 * @returns 返回所有的h函数递归的对象数据
 */
export function dynamicCompressor(components: any, compressor: any) {
    if(!components) return compressor;
    for(let i = 0; i < components.length; i++){
        if('child' in components[i]){
            compressor.push(h(resolveComponent("tdraggable")),{data:components[i]});
        } else {
            compressor.push(h(resolveComponent(components[i].name || components[i].type),{data:components[i]}));
        }
    }
    return compressor;
}