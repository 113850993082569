<template>
    <div class="payable">
        <header class="header boxes">
            <el-steps :active="stepActive" align-center>
                <el-step title="Begin" :icon="Edit" />
                <el-step title="Submit" :icon="Goods" />
                <el-step title="Finish" :icon="CircleCheck" />
            </el-steps>
        </header>
        <main class="main">
            <el-carousel
                :autoplay="false"
                ref="carousel"
                style="height: 600px"
                indicator-position="none"
                :loop="false"
            >
                <el-carousel-item name="usdt" style="height: 600px">
                    <div class="box scan">
                        <el-radio-group v-model="payOption" size="small" @change="onPayChange">
                            <el-radio-button label="TRC20-USDT" />
                            <el-radio-button label="BTC" />
                        </el-radio-group>
                        <div class="chart boxes">
                            <div id="scan" class="img" v-if="isLoadqr" />
                        </div>
                        <el-text class="outline boxes">
                            <span class="address flex">{{ address }}</span>
                            <span class="copy" title="Copy" @click="onCopy">Copy</span>
                        </el-text>
                        <el-button type="success" round @click="onNext" class="submit">
                            Next<el-icon class="el-icon--right"><ArrowRight /></el-icon>
                        </el-button>
                    </div>
                </el-carousel-item>
                <el-carousel-item name="scan" style="height: 400px">
                    <div class="box pay">
                        <div class="reminder">
                            <el-icon color="red"><WarningFilled /></el-icon>
                            Please make sure that the transfer is successful before submitting,
                            otherwise the recharge will fail
                        </div>
                        <el-form :label-position="'top'" label-width="100px" :model="form">
                            <el-form-item label="Amount">
                                <el-input
                                    v-model="form.fullusdt"
                                    class="input"
                                    :formatter="
                                        (value) =>
                                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    "
                                    :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                                />
                            </el-form-item>
                            <el-form-item label="Verification code">
                                <el-input v-model="form.code">
                                    <template #append>
                                        <img :src="vercode" @click="onChangeCode" />
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-button type="danger" round @click="onUsdt" :loading="isSubmit">
                                Submit
                            </el-button>
                        </el-form>
                    </div>
                </el-carousel-item>
                <el-carousel-item name="success" style="height: 400px">
                    <div class="box success">
                        <div v-if="payData.code === '-1'">
                            <div class="icon error">
                                <CircleCloseFilled />
                            </div>
                            <div class="text error" @click="onBack">
                                {{ payData.message }}
                                <span class="etrip"
                                    >（Recharge failed, you can resubmit multiple times click
                                    back?）</span
                                >
                            </div>
                        </div>
                        <div v-else>
                            <div class="icon">
                                <SuccessFilled />
                            </div>
                            <div class="text">
                                {{ payData.message }}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </main>
    </div>
</template>
<script setup>
import Index from './typescript/index';
import { ref, reactive, toRefs, onMounted } from 'vue';
import { Edit, Goods, CircleCheck, ArrowRight } from '@element-plus/icons-vue';
const {
    onNext,
    onUsdt,
    stepActive,
    form,
    carousel,
    payOption,
    address,
    onCopy,
    isLoadqr,
    onPayChange,
    initQRCode,
    onChangeCode,
    vercode,
    payData,
    onBack,
    isSubmit,
} = toRefs(reactive(new Index()));

onMounted(() => {
    let count = 50;
    const inter = setInterval(() => {
        const nodes = document.getElementsByClassName('el-carousel__arrow');
        --count;
        if (!nodes.length || count <= 0) {
            clearInterval(inter);
        } else {
            document.getElementsByClassName('el-carousel__arrow')[0].remove();
        }
    }, 100);
    initQRCode.value();
});
</script>
<style lang="less" scoped>
.payable {
    width: 100%;
    height: 100%;
    overflow: scroll;
    .header {
        height: 100px;
        .el-steps {
            width: 100%;
        }
    }
    .main {
        margin: 0 auto;
        max-width: 600px;
        position: relative;
        .carousel {
            min-height: 600px;
        }
        .el-button {
            width: 100%;
            height: 50px;
            display: block;
            margin-top: 50px;
        }
        .pay {
            width: 60%;
            margin: 0 auto;
            padding-top: 40px;
            text-align: center;
            .input {
                height: 40px;
                background: #e8e8ed;
            }
            .reminder {
                padding: 10px;
                color: #e6a23c;
            }
        }
        .scan {
            .chart {
                width: 340px;
                height: 340px;
                margin: 0 auto;
                .img {
                    display: inline-block;
                }
            }
            .outline {
                color: black;
                border-radius: 6px;
                background-color: var(--el-disabled-bg-color);
                box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
                span {
                    display: inline-block;
                }
                .copy {
                    width: 60px;
                    color: #f56c6c;
                    padding: 10px 0px;
                    border-left: 1px solid var(--el-disabled-border-color);
                }
                .address {
                    padding: 10px 16px;
                }
            }
            .submit {
                width: 80%;
                margin: 0 auto;
                margin-top: 66px;
            }
        }
    }
    .etrip {
        font-size: 14px;
        text-decoration: underline;
    }
    .success {
        width: 100%;
        height: 100%;
        color: green;
        .error {
            color: red;
        }
        .icon {
            margin: 0 auto;
            width: 100px;
            height: 100px;
            padding-top: 60px;
            animation: suc 2s;
        }
        @keyframes suc {
            0% {
                width: 0px;
                height: 0px;
            }
            50% {
                width: 25px;
                height: 25px;
            }
            75% {
                width: 50px;
                height: 50px;
            }
            100% {
                width: 100px;
                height: 100px;
            }
        }
        .text {
            font-size: 32px;
            margin-top: 10px;
        }
    }
}
</style>
