import {reactive} from 'vue';
import {globalDialog} from '@/common/js/tools';
import icons from '@/components/common/xicons/index.vue';
export default class Index{
    private list = reactive([]);
    public onMove = (e:object) => {
        console.log(e);
    }
    public onOpen = () => {
        globalDialog(icons,{message:'提示信息'});
    }
}