import { reactive } from 'vue';
import { IconName } from './awesome';
import { copyElementText } from '@/common/js/tools';
export default class Icons {
    private icons: Array<string> = reactive<Array<string>>(IconName);
    constructor() {
        //console.log(this.icons);
    }
    public onCopy = (name: any) => {
        copyElementText(`<font-awesome-icon class="icon" icon="${name}" />`);
    };
}
