import {reactive} from 'vue';
import { useStore } from "vuex";
import Base from "@/common/js/base";
import { cloneDeep } from 'lodash-es';
import {getUUID} from '@/common/js/tools';
export default class index extends Base{
    private form:any = reactive({});
    private group = reactive({name:"tools",pull:true,put:true});
    constructor(data:any){
        super();
        this.form = data;
    }
    public onHandlebox = (element:any) => {
        this.mitter.emit('dynamicRule',element);
    }
    public onEnd = (e:any) => {
        // console.log(e);
        // if(!e.from.__draggable_context.element.childs.length){
        //     setTimeout(() => {
        //         e.from.remove();
        //     }, 100);
        // }
    }
}