<template>
    <div class="manage layout">
        <!--transition name="el-zoom-in-top">
            <xnotification v-show="isHide" :data="message" @onClose="onClose" @onClick="onClick" />
        </transition -->
        <el-header class="nav boxes">
            <div class="flex l">
                <div class="menu boxes" @mouseenter="isDwon = true" @mouseleave="isDwon = false">
                    <div class="target">
                        <el-icon size="20px" color="#e6a23c">
                            <Coordinate />
                        </el-icon>
                        <span class="text">{{ option }}</span>
                    </div>
                    <el-collapse-transition>
                        <ul class="sub-menu" v-show="isDwon">
                            <li v-for="item in menus" :key="item.key" @click="onSkipped(item)">
                                <el-button :icon="item.icon">{{ item.text }}</el-button>
                            </li>
                        </ul>
                    </el-collapse-transition>
                </div>
            </div>
            <div class="flex r boxes">
                <div class="qa li" @click="onLookup">SSN Look up</div>
                <div class="qa li" @click="isLogin = true" v-if="!user.account">Sign in</div>
                <div class="account li" v-if="user.account">{{ user.account }}</div>
                <div class="money li">{{ user.money }}</div>
                <div class="avatars li" v-html="user.avatar" @click="onAvatarChange" />
            </div>
        </el-header>
        <el-container class="container" id="container">
            <el-main class="main">
                <component :is="dynamic.default" />
            </el-main>
            <transition name="el-zoom-in-top">
                <el-aside class="aside" v-show="isAside">
                    <el-icon class="close" title="close" @click="isAside = false">
                        <CircleClose />
                    </el-icon>
                    <div class="customer">
                        <img src="../../assets/customer.png" />
                    </div>
                    <div class="notification">
                        <div class="msg" v-for="(msg, index) in message" :key="index">
                            <div clas="ol">{{ index + 1 }}、</div>
                            <div class="text" v-html="msg"></div>
                        </div>
                    </div>
                </el-aside>
            </transition>
        </el-container>
        <el-drawer v-model="isDrawer" title="I am the title" direction="rtl"> 侧边栏 </el-drawer>
        <el-dialog v-model="isLogin" :title="openTitle" class="login" width="460px">
            <div class="reminder">{{ reminder }}</div>
            <main class="logo">
                <el-tabs type="border-card" class="demo-tabs" @tab-change="resetReminder">
                    <el-tab-pane>
                        <template #label>
                            <span class="custom-tabs-label">
                                <font-awesome-icon class="icon" icon="user-tie" />
                                <span> Sign in</span>
                            </span>
                        </template>
                        <el-form :label-position="'top'" label-width="100px" :model="form">
                            <el-form-item label="Account">
                                <el-input v-model="form.account" />
                            </el-form-item>
                            <el-form-item label="Password">
                                <el-input v-model="form.password" type="password" />
                            </el-form-item>
                            <el-form-item label="Verification code">
                                <el-input v-model="form.code">
                                    <template #append>
                                        <img :src="vercode" @click="onChangeCode" />
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-button
                                type="success"
                                @click="onSignin"
                                class="login"
                                :loading="isLoginLoding"
                            >
                                Sign in
                            </el-button>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="Config">
                        <template #label>
                            <span class="custom-tabs-label">
                                <font-awesome-icon class="icon" icon="user-plus" />
                                <span> Sign up</span>
                            </span>
                        </template>
                        <el-form :label-position="'top'" label-width="100px" :model="form">
                            <el-form-item label="Account">
                                <el-input v-model="form.account" />
                            </el-form-item>
                            <el-form-item label="Password">
                                <el-input v-model="form.password" type="password" />
                            </el-form-item>
                            <el-form-item label="Confirm password">
                                <el-input v-model="form.surePassword" type="password" />
                            </el-form-item>
                            <el-form-item label="Verification code">
                                <el-input v-model="form.code">
                                    <template #append>
                                        <img :src="vercode" @click="onChangeCode" />
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-button
                                type="success"
                                @click="onSignup"
                                class="login"
                                :loading="isLoginLoding"
                            >
                                Sign up
                            </el-button>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </main>
        </el-dialog>
        <el-dialog v-model="isOpenQA" title="Q&A" class="qdialog">
            <el-form :label-position="'top'" label-width="100px" :model="form">
                <el-form-item>
                    <el-input
                        v-model="form.message"
                        placeholder="Please input QA"
                        maxlength="250"
                        :rows="10"
                        :show-word-limit="true"
                        type="textarea"
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.code">
                        <template #append>
                            <img :src="vercode" @click="onChangeCode" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-button type="info" @click="isOpenQA = false">Cancel</el-button>
                <el-button type="success" @click="onConfirmqa">Confirm</el-button>
            </el-form>
        </el-dialog>
    </div>
</template>
<script setup>
import Index from './typescript/index';
import icons from '@/components/common/xicons/index.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { toRefs, reactive, ref } from 'vue';
const {
    list,
    form,
    menus,
    onMove,
    onOpen,
    avatar,
    isOpenQA,
    onSkipped,
    option,
    isLogin,
    vercode,
    onChangeCode,
    onSignup,
    onSignin,
    reminder,
    user,
    openTitle,
    resetReminder,
    dynamic,
    isAside,
    onLookup,
    onConfirmqa,
    isLoginLoding,
} = toRefs(reactive(new Index()));

const isDwon = ref(false);
const isDrawer = ref(false);
const isHide = ref(true);
const message = reactive(announcement);

const onClose = () => {
    isHide.value = false;
};
const onClick = () => {
    isDrawer.value = true;
};
defineExpose({ onClose });
</script>
<style scoped lang="less">
@import url('../../common/css/public.less');
.manage {
    height: 100%;
    .nav {
        font-size: 14px;
        padding: 0px 40px;
        position: relative;
        .l {
            .menu {
                width: 200px;
                height: 50px;
                font-size: 16px;
                position: relative;
                background: #e8e8ed;
                z-index: 100;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                .sub-menu {
                    top: 50px;
                    left: 0px;
                    width: 200px;
                    color: black;
                    font-weight: bold;
                    background: #ffffff;
                    position: absolute;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    li {
                        text-align: left;
                        padding: 10px 20px;
                        .el-button {
                            background-color: #ffffff;
                        }
                        &:hover {
                            color: var(--bg);
                        }
                        .el-button {
                            border: 0;
                            padding: 0;
                        }
                    }
                    border: solid 1px var(--el-menu-border-color);
                }
                .target {
                    color: var(--bg);
                    width: 100%;
                    display: flex;
                    padding: 0px 10px;
                    align-items: center;
                    justify-content: flex-start;
                    .text {
                        padding-left: 8px;
                    }
                }
                &:hover {
                    box-shadow: var(--el-box-shadow-light);
                    border: solid 1px var(--el-menu-border-color);
                }
            }
        }
        .r {
            justify-content: right;
            .li {
                padding: 0 12px;
                &:not(:last-child) {
                    border-right: 1px solid #ccc;
                }
            }
            .money {
                color: red;
            }
            .money:before {
                content: '$';
            }
            .avatars {
                width: 40px;
                height: 40px;
                padding-right: 0;
            }
            .qa {
                text-decoration: underline;
            }
        }
        &:hover {
            color: var(--bg);
        }
    }
    .container {
        padding: 10px;
    }
    .qdialog {
        .footer {
            padding-top: 16px;
        }
    }
    .reminder {
        color: red;
        padding: 10px;
    }
    .aside {
        width: 300px;
        padding: 40px 10px;
        position: relative;
        border-left: 1px solid #ebeef5;
        img {
            width: 240px;
            height: 240px;
        }
        .notification {
            margin-top: 10px;
            .msg {
                color: var(--bg);
                font-size: 24px;
                text-align: left;
                padding: 4px 0px;
                display: flex;
                animation: blink 4s infinite;
                .ol {
                    width: 20px;
                }
                .text {
                    white-space: initial;
                    word-break: break-all;
                }
            }
        }
    }
    @keyframes blink {
        0% {
            transform: scale(1);
        }
        25% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(0.6);
        }
        100% {
            transform: scale(0.9);
        }
    }
    .close {
        top: 10px;
        right: 10px;
        color: var(--bg);
        font-size: 28px;
        position: absolute;
    }
}
</style>
