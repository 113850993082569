import * as QRCode from 'qrcodejs2';
import { ElMessage, ElLoading } from 'element-plus';
import { reactive, ref, nextTick } from 'vue';
import { copyElementText } from '@/common/js/tools';
import Http from '@/common/js/http';
export default class Index extends Http {
    private address = ref('');
    private form = reactive({
        code: '',
        fullusdt: '',
    });
    private isSubmit = ref(false);
    private vercode = ref('');
    private isLoadqr = ref(false);
    private stepActive = ref(1);
    private carousel: any = ref(null);
    private payOption = ref('TRC20-USDT');
    private step = ['usdt', 'scan', 'success'];
    private payData: any = reactive({});
    constructor() {
        super();
    }
    public onUsdt = () => {
        if (!this.form.fullusdt) {
            ElMessage({ message: 'money cannot be empty', type: 'warning' });
            return;
        }
        if (!this.form.code) {
            ElMessage({ message: 'Verification code cannot be empty', type: 'warning' });
            return;
        }
        this.isSubmit.value = true;
        this.post('/ssn/realRecharge', {
            data: { code: this.form.code, money: this.form.fullusdt },
        })
            .then((data: any) => {
                Object.keys(data).forEach((key: string) => {
                    this.payData[key] = data[key];
                });
                this.onNext();
                this.flushRecharge();
            })
            .catch((e) => {
                ElMessage({ message: e.message, type: 'warning' });
                console.log(e);
            })
            .finally(() => {
                this.isSubmit.value = false;
            });
    };
    private flushRecharge() {
        this.post('/ssn/getUserInfo', { data: { query: '1' } })
            .then((res: any) => {
                this.mitter.emit('listeneruser', res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    public onChangeCode = () => {
        this.vercode.value = `/ssn/verification?t=${new Date().getTime()}`;
    };
    private onNext = () => {
        this.stepActive.value = ++this.stepActive.value;
        this.carousel.value.setActiveItem(this.step[this.stepActive.value - 1]);
    };
    public onCopy = () => {
        copyElementText(this.address.value);
    };
    public onPayChange = (item: any) => {
        console.log(item);
        this.initQRCode();
    };
    public initQRCode = () => {
        this.onChangeCode();
        this.isLoadqr.value = false;
        const el: any = document.querySelector('.chart');
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: el,
        });
        this.post('/ssn/qc', {
            data: { word_type: this.payOption.value === 'BTC' ? 'BTC' : 'TRC' },
        })
            .then((data: any) => {
                this.isLoadqr.value = true;
                this.address.value = data.data;
            })
            .then(() => {
                new QRCode(document.getElementById('scan'), this.address.value);
            })
            .catch((e) => {
                this.isLoadqr.value = true;
                console.log(e);
            })
            .finally(() => {
                this.loading.close();
            });
    };
    public onBack = () => {
        this.isSubmit.value = false;
        this.stepActive.value = --this.stepActive.value;
        this.carousel.value.setActiveItem(this.step[this.stepActive.value - 1]);
    };
}
