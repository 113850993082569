import { reactive, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getUUID } from '@/common/js/tools';
import multiavatar from '@multiavatar/multiavatar';
import { globalDialog } from '@/common/js/tools';
import icons from '@/components/common/xicons/index.vue';
import Page from '@/common/js/page';
import { copyElementText } from '@/common/js/tools';
export default class Order extends Page {
    private columns = [
        'firstname',
        'lastname',
        'city',
        'address',
        'StartDat',
        'aka3fullname',
        'middlename',
        'county_name',
        'aka1fullname',
        'aka2fullname',
        'alt2DOB',
        'alt1DOB',
        'phone1',
        'alt3DOB',
        'ssn',
        'dob',
        'ID',
        'zip',
        'state',
        'name_suff',
    ];
    private isLoadsh = ref(true);
    public ignore = reactive(['ID', 'create_date', 'create_by', 'isSee']);
    private isInner = ref(false);
    private padding = ref('20px 60px 0px 60px');
    public computeAvatar = computed(() => {
        return multiavatar(`${new Date().getTime()}`);
    });
    constructor() {
        super();
        this.install();
    }
    private height = ref('');
    public onInner() {
        this.height.value = `${document.documentElement.clientHeight - 80}px`;
        this.isInner.value = document.documentElement.clientWidth <= 1000;
        this.padding.value = this.isInner.value ? '0px 2px 0px 2px' : '0px 60px 0px 60px';
    }
    public install() {
        this.onInner();
        window.addEventListener('resize', (event) => {
            this.onInner();
        });
    }
    public onCopy = (item: any) => {
        copyElementText(`${JSON.stringify(item)}`);
    };
    public queryList = () => {
        this.post('/ssn/perstateOrderList', { data: { curPage: this.pageSize.value } })
            .then(({ data }: any) => {
                if (data.length) {
                    this.list.push(...data);
                    ++this.pageSize.value;
                }
            })
            .catch((err: any) => {
                console.log(err);
                ElMessage({ message: 'Query Error', type: 'error' });
            })
            .finally(() => {
                this.isLoadsh.value = false;
            });
    };
    public onBack = () => {
        this.router.back();
    };
}
