<template>
    <div class="ssndob">
        <el-tabs type="card" id="card" @tab-change="onTabChange">
            <el-tab-pane label="Buy">
                <el-descriptions :column="1">
                    <el-descriptions-item>
                        <div class="operate">
                            <span class="search" @click="isSearch = true">Search</span>
                            <el-button type="warning" :icon="ShoppingCartFull" @click="onBatch"
                                >Batch</el-button
                            >
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
                <el-table :data="list" border style="width: 100%" @selection-change="onSelected">
                    <el-table-column type="selection" width="55" />
                    <el-table-column prop="order_first_name" label="First Name" />
                    <el-table-column prop="order_center_name" label="MN" width="6px" />
                    <el-table-column prop="order_last_name" label="Last Nmae" />
                    <el-table-column prop="order_address" label="Address" />
                    <el-table-column prop="order_address_city" label="City" />
                    <el-table-column prop="order_address_state" label="State" />
                    <el-table-column prop="order_zip" label="Zip" />
                    <el-table-column prop="order_birthday" label="DOB" />
                    <el-table-column prop="order_ssn" label="SSN" />
                    <!-- <el-table-column prop="order_price" label="DL" />    -->
                    <el-table-column prop="order_n_price" label="price($)" />
                    <el-table-column label="Operate">
                        <template #default="scope">
                            <el-icon class="shop" @click="onShopping(scope.row, scope.$index)"
                                ><ShoppingCartFull
                            /></el-icon>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="height: 40px" />
                <div class="pagination">
                    <el-pagination
                        v-model:current-page="pageSize"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        @size-change="onChange"
                        @current-change="onChange"
                    />
                </div>
            </el-tab-pane>
            <el-tab-pane label="Record">
                <el-descriptions :column="1">
                    <el-descriptions-item>
                        <div class="operate">
                            <span class="search" @click="isSearch = true">Search</span>
                            <el-button type="warning" :icon="Sell" @click="isBatchExport = true"
                                >Export</el-button
                            >
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
                <el-table :data="list" border style="width: 100%">
                    <el-table-column prop="order_first_name" label="First Name" />
                    <el-table-column label="Operate" width="100px">
                        <template #default="scope">
                            <el-icon class="shop" @click="onExport(scope.row, scope.$index)"
                                ><CopyDocument
                            /></el-icon>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="height: 40px" />
                <div class="pagination">
                    <el-pagination
                        v-model:current-page="pageSize"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        @size-change="onChange"
                        @current-change="onChange"
                    />
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog v-model="isSearch" title="Search" width="50%">
            <el-form :label-position="'top'" label-width="100px" :model="form">
                <el-descriptions class="margin-top" title=" " :column="3" border>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">DOB</div>
                        </template>
                        <el-date-picker
                            v-model="form.birthday"
                            type="date"
                            format="DD/MM/YYYY"
                            placeholder="Select date"
                        />
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">City</div>
                        </template>
                        <el-input v-model="form.city" placeholder="Please input City">
                            <template #prefix>
                                <el-icon class="el-input__icon"><School /></el-icon>
                            </template>
                        </el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">Zip</div>
                        </template>
                        <el-input v-model="form.zip" placeholder="Please input Zip">
                            <template #prefix>
                                <el-icon class="el-input__icon"><Files /></el-icon>
                            </template>
                        </el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">State</div>
                        </template>
                        <el-select v-model="form.state" style="width: 220px" placeholder="Select">
                            <template #prefix>
                                <el-icon class="el-input__icon"><Van /></el-icon>
                            </template>
                            <el-option
                                v-for="item in states"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="1">
                    <el-descriptions-item>
                        <div class="screen">
                            <el-button type="info" @click="onReset">Reset</el-button>
                            <el-button type="success" @click="onSearch">Confirm</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-form>
        </el-dialog>
        <el-dialog v-model="isBatchExport" title="Export" width="50%">
            <el-form :label-position="'top'" label-width="100px" :model="expForm">
                <el-descriptions class="margin-top" title=" " :column="1" border>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">Export page size</div>
                        </template>
                        <el-input-number v-model="expForm.page" :step="1" :min="1" :max="expmax" />
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <div class="cell-item">Code</div>
                        </template>
                        <el-input v-model="expForm.code">
                            <template #append>
                                <img :src="vercode" @click="onChangeCode" />
                            </template>
                        </el-input>
                    </el-descriptions-item>
                </el-descriptions>

                <div class="el-form-item">
                    <el-button type="danger" @click="onBatchExport">Confirm</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script setup>
import Index from './typescript/index';
import { ref, reactive, toRefs, onMounted } from 'vue';
import {
    Edit,
    Goods,
    CircleCheck,
    ArrowRight,
    ShoppingCartFull,
    Sell,
} from '@element-plus/icons-vue';
const {
    list,
    form,
    states,
    isSearch,
    onSearch,
    onReset,
    onBatch,
    onSelected,
    onShopping,
    total,
    onChange,
    pageSize,
    onExport,
    onTabChange,
    expmax,
    vercode,
    expForm,
    onChangeCode,
    onBatchExport,
    isBatchExport,
} = toRefs(reactive(new Index()));
</script>
<style lang="less" scoped>
.ssndob {
    width: 100%;
    height: 100%;
    overflow: scroll;
    :deep(.el-descriptions__header) {
        margin: 0;
    }
    .el-icon {
        vertical-align: middle;
    }
    .screen {
        padding-top: 20px;
        text-align: center;
    }
    .operate {
        text-align: right;
        .search {
            margin-right: 10px;
            text-decoration: underline;
            color: var(--el-color-primary);
        }
    }
    .shop {
        font-size: 24px;
        color: #e6a23c;
    }
    .pagination {
        width: 100%;
        left: 14px;
        bottom: 14px;
        position: fixed;
        display: flex;
        z-index: 1000;
        text-align: right;
        background: #ffffff;
        justify-content: flex-start;
    }
    .el-form-item {
        text-align: center;
        padding: 8px 11px;
        border: 1px solid #ebeef5;
    }
}
</style>
