import {reactive,provide} from 'vue';
import Base from "@/common/js/base";
import { cloneDeep } from 'lodash-es';
import {getUUID} from '@/common/js/tools';
export default class Views extends Base{
    private target:any = reactive([]);
    private group = reactive({name:"tools",pull:true,put:true});
    constructor(){
        super();
        this.mitter.on('createTool',this.onAdd);
    }
    public onAdd = (e:any) => {
        let line;
        if('newDraggableIndex' in e){ // 拖拽过来的
            line = cloneDeep(this.list[e.newDraggableIndex]);
        } else {// 点击过来的
            this.list.push(e);
            line = cloneDeep(e);
        }
        line.key = getUUID();
        this.target.push({name:"panel",key:getUUID(),childs:[line]});
        this.list.splice(1);// 删除让外层只循环一次，借助vuedraggable拖拽功能
        this.store.dispatch("setFormElements",this.target);
    }
    public onBnt = () => {
        console.log(this.store.state.formData);
    }
    public onEnd = (e:any) => {
        console.log("onend",e);
    }
}