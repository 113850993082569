import Base from "@/common/js/base";
import { reactive } from 'vue';
export default class Rules extends Base{
    private names:Array<string> = reactive<Array<string>>([]);
    private rules:any = reactive({});
    constructor(){
        super();
        this.init();
    }
    private init(){
        this.mitter.on('dynamicRule',(element:any) => {this.onHandleRule(element)});//
    }
    private onHandleRule(element:any){
        console.log(element);
        const Rules = require(`@/components/base/${element.name}/typescript/rules`);
        const rules = new Rules.default();
        this.names.splice(0,this.names.length);
        Object.keys(rules).forEach((key:string) => {
            this.names.push(key);
            this.rules[key] = {title:element.name,text:rules[key]};
        });
    }
}