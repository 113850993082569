<template>
    <div class="pentester">
        <nav class="nav">
            <div class="flex l boxes"></div>
            <div class="flex r boxes">
                <div class="qa li" @click="onRouter('pentester/order')">
                    Order
                    <el-icon>
                        <ShoppingCartFull />
                    </el-icon>
                </div>
                <div class="qa li">
                    <span class="router" @click="onRouter('market')">SSN Purchase</span>
                </div>
                <div class="qa li" @click="isLogin = true" v-if="!user.account">Sign in</div>
                <div class="qa li" @click="isRecharge = true">Recharge</div>
                <div class="account li" v-if="user.account">{{ user.account }}</div>
                <div class="money li">{{ user.money }}</div>
                <div class="avatars li" v-html="user.avatar" />
            </div>
        </nav>
        <header class="header">
            <section class="search">
                <div class="logo">
                    <div class="left">Usassn.shop <span class="trip">SSN Look Up</span></div>
                    <div class="right">This option can be empty</div>
                </div>
                <div class="operate" :keys="isInner" :class="isInner ? 'row' : 'col'">
                    <el-input
                        v-model="search.firstname"
                        class="input gap"
                        placeholder="First Name (Required)"
                    />
                    <el-input
                        v-model="search.lastname"
                        class="input gap"
                        placeholder="Last Name (Required)"
                    />
                    <el-select
                        v-model="search.state"
                        placeholder="State (Required)"
                        size="large"
                        class="input gap"
                    >
                        <el-option
                            v-for="opt in states"
                            :key="opt.state"
                            :label="opt.state"
                            :value="opt.state"
                        />
                    </el-select>
                    <el-input-number
                        v-model="search.birthyear"
                        class="mx-4 number gap"
                        :min="1900"
                        size="large"
                        placeholder="Birth Year (Required)"
                        controls-position="right"
                    />
                    <el-button type="primary" class="bnt" @click="onSearch">Search</el-button>
                </div>
            </section>
        </header>
        <main class="main" :style="{ '--padding': padding }">
            <el-icon class="divtion" v-if="isLoadsh"><Refresh /></el-icon>
            <div class="container">
                <div class="decorate" v-if="isView">
                    <div class="ifyou">If you're looking for answers, click Search</div>
                </div>
                <div class="consequence" v-if="cases.length">
                    <el-card class="card" v-for="item in cases" :key="item.ID" shadow="hover">
                        <el-descriptions>
                            <template #title>
                                <div class="outline">
                                    <div class="left">
                                        <span class="userInfo" v-html="computeAvatar" />
                                        <span>User Info</span>
                                    </div>
                                    <div class="right" @click="onCopy(item)">Copy</div>
                                </div>
                            </template>
                            <el-descriptions-item
                                v-for="key in columns"
                                v-show="!ignore.includes(key)"
                                :key="key"
                            >
                                <template #label>
                                    <span>{{ key }}：</span>
                                </template>
                                <span>
                                    <span class="value">{{ item[key] || '-' }}</span>
                                    <span
                                        v-if="key === 'ssn'"
                                        :class="{ see: key === 'ssn' }"
                                        @click="onSee(item)"
                                    >
                                        <span v-if="!item.isSee" v-show="!item.isShow"> See</span>
                                        <span class="divtion" v-else v-show="!item.isShow">
                                            <el-icon class="divtion"><Refresh /></el-icon
                                        ></span>
                                    </span>
                                </span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-card>
                </div>
                <div v-if="isNoData" class="nodata">No Data</div>
            </div>
        </main>
        <el-dialog v-model="isLogin" :title="openTitle" class="login" width="460px">
            <div class="reminder">{{ reminder }}</div>
            <main class="logo">
                <el-tabs type="border-card" class="demo-tabs" @tab-change="resetReminder">
                    <el-tab-pane>
                        <template #label>
                            <span class="custom-tabs-label">
                                <font-awesome-icon class="icon" icon="user-tie" />
                                <span> Sign in</span>
                            </span>
                        </template>
                        <el-form :label-position="'top'" label-width="100px" :model="form">
                            <el-form-item label="Account">
                                <el-input v-model="form.account" />
                            </el-form-item>
                            <el-form-item label="Password">
                                <el-input v-model="form.password" type="password" />
                            </el-form-item>
                            <el-form-item label="Verification code">
                                <el-input v-model="form.code">
                                    <template #append>
                                        <img :src="vercode" @click="onChangeCode" />
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-button
                                type="success"
                                @click="onSignin"
                                class="login"
                                :loading="isLoginLoding"
                            >
                                Sign in
                            </el-button>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="Config">
                        <template #label>
                            <span class="custom-tabs-label">
                                <font-awesome-icon class="icon" icon="user-plus" />
                                <span> Sign up</span>
                            </span>
                        </template>
                        <el-form :label-position="'top'" label-width="100px" :model="form">
                            <el-form-item label="Account">
                                <el-input v-model="form.account" />
                            </el-form-item>
                            <el-form-item label="Password">
                                <el-input v-model="form.password" type="password" />
                            </el-form-item>
                            <el-form-item label="Confirm password">
                                <el-input v-model="form.surePassword" type="password" />
                            </el-form-item>
                            <el-form-item label="Verification code">
                                <el-input v-model="form.code">
                                    <template #append>
                                        <img :src="vercode" @click="onChangeCode" />
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-button
                                type="success"
                                @click="onSignup"
                                class="login"
                                :loading="isLoginLoding"
                            >
                                Sign up
                            </el-button>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </main>
        </el-dialog>
        <el-dialog v-model="isRecharge" title="Recharge" class="login" width="80%">
            <payable />
        </el-dialog>
    </div>
</template>
<script setup>
import { toRefs, reactive } from 'vue';
import Index from './typescript/index';
import payable from '../market/payable/index.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
const {
    onSee,
    user,
    form,
    cases,
    isView,
    isNoData,
    isLoadsh,
    onSubmit,
    onSearch,
    search,
    ignore,
    onCopy,
    isRecharge,
    list,
    menus,
    states,
    onMove,
    onOpen,
    avatar,
    isOpenQA,
    onSkipped,
    option,
    isLogin,
    vercode,
    onRouter,
    computeAvatar,
    onChangeCode,
    onSignup,
    onSignin,
    reminder,
    openTitle,
    isInner,
    columns,
    resetReminder,
    dynamic,
    isAside,
    padding,
    onConfirmqa,
    isLoginLoding,
} = toRefs(reactive(new Index()));
</script>
<style lang="less">
@import url('../../common/css/public.less');
.pentester {
    height: 100%;
    --bg-color: rgba(33, 37, 41);
    background-color: #0d0d0d;
    color: #ffffff;
    .nav {
        height: 40px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;
        .r {
            justify-content: right;
            .li {
                padding: 0 12px;
                &:not(:last-child) {
                    border-right: 1px solid #ccc;
                }
            }
            .money {
                color: red;
            }
            .money:before {
                content: '$';
            }
            .avatars {
                width: 28px;
                height: 28px;
                padding-right: 0;
            }
            .qa {
                text-decoration: underline;
                .el-icon {
                    vertical-align: middle;
                }
            }
        }
        &:hover {
            color: var(--bg);
        }
    }
    .header {
        .search {
            padding: 16px 60px;
            background-color: rgba(33, 37, 41);
        }
        .logo {
            font-size: 37px;
            text-align: left;
            display: flex;
            align-items: center;
            padding: 16px 0px 24px 0px;
            justify-content: space-between;
            .right {
                color: red;
                font-size: 18px;
            }
        }
        .input {
            flex: 1;
        }
        .number {
            min-width: 100px;
        }
        .col {
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
        }
        .row {
            .gap {
                display: flex;
                min-width: 100%;
                text-align: center;
                margin-bottom: 10px;
                align-items: center;
                justify-content: center;
                .select-trigger {
                    width: 100%;
                }
            }
        }
        .operate {
            > .el-select {
                width: 160px;
            }
            > .bnt {
                --el-color-primary: #ffca2c;
                --el-button-hover-bg-color: #ffc107;
                --el-button-hover-border-color: #ffc107;
                --el-color-primary-dark-2: #ffc107;
                height: 40px;
            }
            .gap {
                height: 40px;
                margin-right: 10px;
            }
        }
        .remark {
            margin-top: 20px;
            color: red;
            text-align: left;
        }
    }
    .main {
        height: 100%;
        overflow: scroll;
        padding: var(--padding);
        .container {
            border-radius: 8px;
            margin-bottom: 263px;
            background-color: #0d0d0d;
            .decorate {
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .left {
                    width: 38%;
                    padding: 10px;
                }
                .right {
                    flex: 1;
                    text-align: left;
                    h1 {
                        font-weight: 500;
                        margin-bottom: 1.5rem !important;
                        font-size: calc(1.375rem + 1.5vw);
                    }
                    h5 {
                        font-size: 20px;
                        margin-bottom: 1rem !important;
                    }
                }
            }
        }
    }
    .router {
        color: #ffa500;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
    }
    .ifyou {
        color: red;
        font-size: 26px;
    }
    .login {
        color: #ffffff;
        --el-dialog-bg-color: rgba(33, 37, 41);
        .el-dialog__title {
            color: #ffffff;
        }
    }
    .el-dialog__body,
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .logo,
    .el-form-item__label {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
        --el-dialog-bg-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card,
    .el-tabs__header,
    .el-tabs__content,
    .el-form-item__label,
    .el-descriptions__table {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-tabs--border-card {
        border: 1px solid gray;
    }
    .el-tabs--border-card > .el-tabs__header {
        border-bottom: 1px solid rgba(33, 37, 41);
    }

    .el-tabs__item:hover,
    .el-step__title {
        color: #ffffff;
    }
    .is-finish {
        color: var(--el-color-primary) !important;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .el-step__icon {
        background: var(--bg-color);
    }
    .consequence {
        background-color: #0d0d0d;
        // --el-box-shadow-light: 0px 0px 20px gray;
    }
    .card {
        width: 100%;
        margin-top: 16px;
        color: #ffffff;
        background-color: rgba(33, 37, 41);
    }
    .is-hover-shadow {
        &:hover {
            transform: scale(01.01);
        }
    }
    .outline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right {
            color: red;
            padding-right: 10px;
            text-decoration: underline;
        }
        .left {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .userInfo {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        display: inline-block;
    }
    .see {
        color: red;
        font-size: 12px;
        margin-left: 8px;
        text-decoration: underline;
        .el-icon {
            margin-left: 4px;
            font-size: 16px;
        }
    }
    .el-descriptions__title,
    .el-descriptions__label,
    .el-descriptions__content {
        .value {
            // vertical-align: sub;
        }
        color: #ffffff;
    }
    .el-descriptions__label {
        font-weight: 700;
        font-size: 16px;
    }
    .nodata {
        color: #ffffff;
        background-color: #0d0d0d;
    }
    @keyframes divnim {
        from {
            transform: scale(1);
            transform: rotate(0deg);
        }
        to {
            transform: scale(1.01);
            transform: rotate(360deg);
        }
    }
    .trip {
        font-size: 18px;
    }
    .divtion {
        font-size: 24px;
        animation: divnim 1s linear infinite;
    }
    .el-descriptions__title {
        width: 100%;
    }
}
</style>
