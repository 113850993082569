<template>
    <vuedraggable v-model="form.childs" v-if="form.childs.length" :group="group" animation="300" @end="onEnd" class="xdraggable" :itemKey="key => key">
        <template #item="{element,index}">
            <div class="box" :key="index" @click="onHandlebox(element)">
                <tdraggable :data="element"/>
            </div>
        </template>
    </vuedraggable>
</template>
<script lang="ts" setup>
import {toRefs,reactive,inject} from 'vue';
import vuedraggable from 'vuedraggable';
import Index from './typescript/index';
const  props = defineProps(['data']);
const {group,form,onHandlebox,onEnd} = toRefs(reactive(new Index(props.data)));
</script>