<template>
    <div class="icons">
        <div class="box" v-for="(icon, index) in icons" :key="index" @click="onCopy(icon)">
            <font-awesome-icon class="icon" :icon="icon" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { toRefs, reactive } from 'vue';
import ICons from './typescript/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
const { icons, onCopy } = toRefs(reactive(new ICons()));
</script>
<style lang="less" scoped>
.icons {
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: #ffffff;
    .box {
        width: 60px;
        margin: 8px;
        height: 60px;
        line-height: 68px;
        border-radius: 4px;
        background: #ccc;
        display: inline-block;
        text-align: center;
        border: 1px solid #ccc;
        .icon {
            width: 30px;
            height: 30px;
            color: gray;
            display: inline-block;
        }
    }
}
</style>
