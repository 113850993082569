import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, reactive, inject, Ref } from 'vue';
import Http from './http';
export default class Page extends Http {
    protected vercode: any = ref('');
    protected store: any;
    protected router: any;
    protected context: any;
    protected limit: any = 10;
    protected offset: any = 0;
    protected isLoad: any = ref(false);
    protected themeColor: any = ref('');
    protected isNotdata: any = ref(false);
    protected isFinish: any = ref(false);
    protected messages: Array<string> = [];
    protected pageSize: any = ref(1);
    protected pageNum: number = 10;
    protected isRefresh: any = ref(false);
    protected list: any = reactive([]);
    protected finishText: Ref<string> = ref('more');
    protected listOption: any = {
        head: {},
        config: {},
    };
    constructor() {
        super();
        this.store = useStore();
        this.router = useRouter();
        this.themeColor.value = this.store.state.themeColor;
    }
    onRefresh = () => {};
    onLoad = () => {};
}
