<template>
    <!-- div >
        <Flow/>
    </div -->
    <vuedraggable :list="list" :group="group" @add="onAdd" @end="onEnd" animation="500" class="views" :itemKey="key => key">
        <template #item="{index}">
            <vuedraggable v-model="target" :group="group" :line="index" :force-fallback="true" :itemKey="key => key" class="container">
                <template #item="{element}">
                    <xdraggable :data="element"/>
                </template>
            </vuedraggable>
        </template>
    </vuedraggable>
</template>
<script lang="ts" setup>
    import {reactive,toRefs} from 'vue';
    import Views from '../typescript/views';
    import Flow from './flow.vue';
    import vuedraggable from 'vuedraggable';
    const {list,group,onAdd,onBnt,target,onEnd} = toRefs(reactive(new Views()))
</script>
<style lang="less" scoped>
    .views{
        height: 100%;
    }
</style>