import G6 from '@antv/g6';
import {reactive} from 'vue';
export default class Index{
    private graph:any;
    private elements:any = reactive([]);
    constructor(){
        this.init();
    }
    private init(){
        this.initGraph();
    }
    private initGraph(){
        const container:any = document.querySelector(".flow");
        this.graph = new G6.Graph({
            container:'container',
            width:container.clientWidth,
            height:container.clientHeight,
            modes: {
              default: ['drag-node', 'drag-canvas', 'zoom-canvas'],
            },
            plugins:[
              {
                type:'minimap',
              }
            ]
        });
        const data = {
          nodes:[
              {
                id:'node1',
                x:100,
                y:200,
                type:'circle',
                label:'start',
                size:50,
                style:{
                  fill:'#00FFFF',
                  stroke:'#FFFF00',
                  lineWidth:5
                }
              },
              {
                id:'node2',
                x:300,
                y:200,
                label:'end',
                type:'circle',
                size:40,
                style:{
                  fill:'#00FFFF',
                  stroke:'#FFFF00',
                  lineWidth:5
                }
              }
          ],
          edges:[
            {
              source:'node1',
              target:'node2',
              label:'我是连续',
              style:{
                stroke:'#000000',
              }
            }
          ]
      }
        this.graph.read(data);
    }
}
// const data = {
//   nodes:[
//       {
//         id:'node1',
//         x:100,
//         y:200,
//         label:'start',
//         type:'circle',
//         size:40,
//         labelCfg:{
//           position:'center',
//           style:{
//             fontSize:10,
//             color:'red'
//           }
//         }
//       },
//       {
//         id:'node2',
//         x:300,
//         y:200,
//         label:'end',
//         type:'circle',
//         size:40,
//         labelCfg:{
//           position:'center',
//           style:{
//             fontSize:10,
//             color:'red'
//           }
//         }
//       }
//   ],
//   edges:[
//     {
//       source:'node1',
//       target:'node2',
//       label:'我是连续',
//       keyShape:{
//         stroke:'red'
//       }
//     }
//   ]
// }