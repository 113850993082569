import {reactive} from 'vue';
import Base from '@/common/js/base';
import {tools} from './config';
import { cloneDeep } from 'lodash-es';
export default class Tools extends Base{
    private names:Array<string> = reactive([]);
    private group = reactive({name:"tools",pull:'clone',put:false});
    constructor(){
        super();
        this.init();
    }
    private init(){
        // 初始化组件菜单栏
        this.names.push(...Object.keys(tools));// 获取key进行初始化打开
        this.names.forEach((key:string) => {
            this.list.push(tools[key]);
        });
    };
    public onHitBox = (element:ToolType) => {
        this.mitter.emit('createTool',element);
    }
    public onMove = (e:any) => {
        if(e.from === e.to){
            return false;
        }
        //console.log("onMove = ",e);
    }
    public onEnd = (e:any) => {
       //console.log("onEnd = ",e);
    }
}