import { cloneDeep } from 'lodash-es';
import { createApp } from 'vue';
import { ElMessage } from 'element-plus';
export const letter = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const getUUID = (num = 8) => {
    let text = '';
    for (let i = 0; i < num; i++) {
        text += letter[Math.ceil(Math.random() * 52)] || 'c';
    }
    return text;
};
export const globalDialog = (comopnent: any, params: any) => {
    let dialog = document.querySelector('#x-dialog');
    const app = createApp(comopnent, params);
    if (dialog) dialog.remove();
    dialog = document.createElement('div');
    const cloth = document.createElement('div');
    cloth.setAttribute('class', 'x-clothe');
    document.body.append(cloth);
    document.body.append(dialog);
    dialog.setAttribute('class', 'x-dialog');
    dialog.setAttribute('id', 'x-dialog');
    cloth.onclick = () => {
        window.closeDialog();
    };
    window.closeDialog = () => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            if (cloth) cloth.remove();
            if (dialog) dialog.remove();
        }, 100);
    };
    app.mount(dialog);
};
export function copyElementText(text: string) {
    let copyNode: any = document.getElementById('iconcopy');
    if (copyNode) {
        copyNode.value = text;
    } else {
        const body = document.body;
        copyNode = document.createElement('input');
        copyNode.setAttribute('id', 'iconcopy');
        copyNode.setAttribute('style', 'height:0');
        copyNode.value = text;
        body.appendChild(copyNode);
    }
    copyNode.select();
    document.execCommand('Copy');
    ElMessage({ message: 'Copy Success', type: 'success' });
}
export function setStorage(key: string, data: any) {
    if (!key) {
        console.log('key is not null');
        return;
    }
    if (typeof data === 'object') {
        localStorage.setItem(key, cloneDeep(data));
    } else {
        localStorage.setItem(key, data);
    }
}
export function getStorage(key: string) {
    if (!key) {
        console.log('key is not null');
        return;
    }
    return JSON.parse(localStorage.getItem(key) || '');
}
