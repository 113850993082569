<template>
    <el-watermark
        :content="['usassn.shop']"
        class="watermark"
    >
        <router-view />
    </el-watermark>
</template>

<style lang="less">
.watermark {
    width: 100%;
    height: 100%;
}
</style>
