import { ElMessage, ElLoading } from 'element-plus';
import { onMounted, reactive, ref } from 'vue';
import Page from '@/common/js/page';
import dayjs from 'dayjs';
import { copyElementText } from '@/common/js/tools';
export default class Index extends Page {
    private form: any = reactive({
        eg: '',
        reg: '',
    });
    private urls: any = {
        0: '/ssn/passList',
        1: '/ssn/passMeList',
    };
    private expmax: any = ref(1);
    private expForm: any = reactive({
        code: '',
        size: 5000,
        page: 1,
    });
    private isBatchExport: any = ref(false);
    private tabIndex: string = '0';
    private el: any;
    private total: any = ref(1);
    private options: any = reactive([]);
    private isSearch: any = ref(false);
    constructor() {
        super();
        this.onChangeCode();
        onMounted(() => {
            this.queryDate();
            this.el = document.getElementById('container');
        });
    }
    private onTabChange = (tab: any) => {
        this.onReset();
        this.tabIndex = tab;
        this.queryDate();
    };
    public onSearch = () => {
        this.isSearch.value = false;
        this.queryDate();
    };
    public onReset = () => {
        this.pageSize.value = 1;
        Object.keys(this.form).forEach((key: string) => {
            this.form[key] = '';
        });
    };
    private queryDate() {
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        this.list.splice(0, this.list.length);
        this.form.reg = this.form.eg;
        this.post(this.urls[this.tabIndex], {
            data: Object.assign({ curPage: this.pageSize.value }, this.form),
        })
            .then((data: any) => {
                this.total.value = data.data.length ? data.data[0].total : 0;
                if (+this.tabIndex) {
                    this.list.push(...data.data);
                } else {
                    this.list.push(
                        ...data.data.map((item: any) => {
                            if (item.pass_value) {
                                let arraystr = item.pass_value.split('#%#image');
                                if (arraystr.length > 1) {
                                    item.pass_value = arraystr[0];
                                    item.pass_image = arraystr[1];
                                }
                            }
                            return item;
                        })
                    );
                }
                this.expmax.value = Math.ceil(
                    !this.total.value ? 1 : this.total.value / this.expForm.page
                );
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'error' });
            })
            .finally(() => {
                this.loading.close();
            });
    }
    public onShopping = (row: any, index: number) => {
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        let option = { pass_id: row.pass_id, order_price: row.pass_price };
        this.post('/ssn/buypass', { data: option })
            .then((data: any) => {
                this.flushRecharge();
                this.list.splice(index, 1);
                ElMessage({ message: data.message, type: 'success' });
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'error' });
            })
            .finally(() => {
                this.loading.close();
            });
    };
    public onBatch = () => {
        const selection = this.options.map((m: any) => m.pass_id);
        if (!selection.length) {
            ElMessage({ message: 'Order cannot be empty', type: 'warning' });
            return;
        }
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        let option = { pass_id: selection.join(',') };
        this.post('/ssn/rbb', { data: option })
            .then((data: any) => {
                if (data.code === '1000') {
                    this.flushRecharge();
                    const timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        this.queryDate();
                    }, 1000);
                }
                ElMessage({
                    message: data.message,
                    type: data.code === '1000' ? 'success' : 'warning',
                });
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'warning' });
            })
            .finally(() => {
                this.loading.close();
            });
    };
    public onSelected = (data: any) => {
        this.options.splice(0, this.options.length);
        this.options.push(...data);
    };
    private flushRecharge() {
        this.post('/ssn/getUserInfo', { data: { query: '1' } })
            .then((res: any) => {
                this.mitter.emit('listeneruser', res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    public onChange = () => {
        this.queryDate();
    };
    public onExport = (row: any) => {
        copyElementText(row.pass_value);
    };
    public onBatchExport = () => {
        if (!this.expForm.code) {
            ElMessage({ message: 'code cannot be empty', type: 'warning' });
            return;
        }
        this.loading = ElLoading.service({
            lock: true,
            text: 'loading',
            background: 'rgba(0, 0, 0, 0.7)',
            target: this.el,
        });
        this.post('/ssn/export', {
            responseType: 'blob',
            timeout: 120 * 1000,
            data: { code: this.expForm.code, size: this.expForm.size, page: this.expForm.page },
        })
            .then((data: any) => {
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = window.URL.createObjectURL(new Blob([data]));
                link.setAttribute('download', `${new Date().getTime()}.txt`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            })
            .catch((e) => {
                console.log(e);
                ElMessage({ message: e.message, type: 'warning' });
                this.onChangeCode();
            })
            .finally(() => {
                this.loading.close();
                this.isBatchExport.value = false;
            });
    };
    public onChangeCode = () => {
        this.vercode.value = `/ssn/verification?t=${new Date().getTime()}`;
    };
}
