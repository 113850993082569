import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getUUID } from '@/common/js/tools';
import multiavatar from '@multiavatar/multiavatar';
import { globalDialog } from '@/common/js/tools';
import icons from '@/components/common/xicons/index.vue';
import Page from '@/common/js/page';
export default class Index extends Page {
    private user: any = reactive({
        money: 0,
        avatar: multiavatar('1234'),
        account: '',
    });
    private search: any = reactive({
        firstname: '',
        lastname: '',
        state: '',
        birthyear: 1900,
    });
    private fileList = reactive([]);
    private openTitle = ref('Sign in');
    private reminder = ref('');
    private isAside = ref(true);
    private isLogin = ref(false);
    private isRecharge = ref(false);
    private isLoginLoding = ref(false);
    private option = ref('Buy ssndob');
    private isOpenQA = ref(false);
    private menus = reactive([
        { key: 'ssndob', text: 'Buy ssndob', icon: 'ShoppingCart' },
        { key: 'possport', text: 'Possport photo', icon: 'ShoppingCartFull' },
        // { key: 'history', text: 'Order History', icon: 'Tickets' },
        { key: 'payable', text: 'Bitcoin', icon: 'Promotion' },
        { key: 'qa', text: 'Q&A', icon: 'Service' },
        { key: 'exit', text: 'Exit', icon: 'SwitchButton' },
    ]);
    private form: any = reactive({
        account: '',
        password: '',
        surePassword: '',
        code: '',
        invite: '',
        message: '',
    });
    constructor() {
        super();
        this.install();
    }
    public onChangeCode = () => {
        this.vercode.value = `/ssn/verification?t=${getUUID()}`;
    };
    public install() {
        this.onChangeCode();
        this.mitter.on('listeneruser', (data: any) => {
            this.onUserInfo(data);
        });
    }
    private onUserInfo(data: any) {
        this.isLogin.value = data ? false : true;
        this.user.money = data ? data.user_money : 0;
        this.user.account = data ? data.user_account : '';
        this.user.avatar = multiavatar(data ? data.user_invite : getUUID());
    }
    public onMove = (e: object) => {
        console.log(e);
    };
    public onOpen = () => {
        globalDialog(icons, { message: '提示信息' });
    };
    public onSkipped = (item: any) => {
        this.isAside.value = true;
        if (item.key === 'qa') {
            this.isOpenQA.value = true;
        } else if (item.key === 'exit') {
            this.onExit();
        } else {
            this.option.value = item.text;
        }
    };
    public onConfirmqa = () => {
        if (!this.form.code) {
            ElMessage({ message: 'code is not null', type: 'warning' });
            return;
        }
        if (!this.form.message) {
            ElMessage({ message: 'message is not null', type: 'warning' });
            return;
        }
        const option = {
            code: this.form.code,
            message: this.form.message,
        };
        this.post('/ssn/question', { data: option })
            .then((res: any) => {
                this.isOpenQA.value = false;
                ElMessage({ message: 'Success', type: 'success' });
            })
            .catch((err: any) => {
                ElMessage({ message: err.message, type: 'error' });
            })
            .finally(() => {
                this.onChangeCode();
            });
    };
    public onSignup = () => {
        if (this.form.surePassword !== this.form.password) {
            this.reminder.value = 'Inconsistent password.';
            return;
        }
        this.isLoginLoding.value = true;
        const option = {
            user_account: this.form.account,
            user_password: this.form.password,
            sure_password: this.form.surePassword,
            user_invite: this.form.invite,
            check_code: this.form.code,
        };
        this.post('/ssn/register', { data: option })
            .then((res: any) => {
                if (res.code === '-1') {
                    this.reminder.value = res.message;
                } else {
                    this.isLogin.value = false;
                    ElMessage({ message: res.message, type: 'success' });
                }
            })
            .catch((err: any) => {
                this.reminder.value = err.message;
            })
            .finally(() => {
                this.isLoginLoding.value = false;
                this.onChangeCode();
            });
    };
    public onSignin = () => {
        let option = {
            user_account: this.form.account,
            user_password: this.form.password,
            vcode: this.form.code,
        };
        this.isLoginLoding.value = true;
        this.post('/ssn/login', { data: option })
            .then((res: any) => {
                if (res.code === '-1') {
                    this.reminder.value = res.message;
                } else {
                    this.isLogin.value = false;
                    ElMessage({ message: res.message, type: 'success' });
                }
            })
            .catch((err: any) => {
                this.reminder.value = err.message;
            })
            .finally(() => {
                this.isLoginLoding.value = false;
                this.onChangeCode();
            });
    };
    public resetReminder = (index: number) => {
        this.form.code = '';
        this.reminder.value = '';
        this.form.account = '';
        this.form.password = '';
        this.form.surePassword = '';
        this.form.invite = '';
        this.isLoginLoding.value = false;
        this.openTitle.value = index ? 'Sign up' : 'Sign in';
    };
    public onExit = () => {
        ElMessageBox.confirm('Are you sure to log out ?', 'usassn.shop', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
        })
            .then(() => {
                this.post('/ssn/logout')
                    .then((res: any) => {
                        this.user.account = '';
                        ElMessage({ type: 'success', message: res.message });
                    })
                    .catch((err) => {
                        ElMessage({ type: 'error', message: err.message });
                    });
            })
            .catch(() => {});
    };
    private textarea: any = ref('');
    private isInsert = ref(true);
    public onUploadChange = (file: any) => {
        console.log(file);
        if (file.raw) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                this.onDate(event.target.result);
            };

            reader.onerror = function () {
                console.error('Error reading the file.');
            };

            reader.readAsText(file.raw); // 读取文件内容
        } else {
            console.log('No file selected');
        }
    };
    public onSubmit = () => {
        this.onDate(this.textarea.value);
    };
    private onDate = (data: any) => {
        const keys =
            'ID,firstname,lastname,middlename,name_suff,dob,address,city,county_name,st,zip,phone1,aka1fullname,aka2fullname,aka3fullname,StartDat,alt1DOB,alt2DOB,alt3DOB,ssn'.split(
                ','
            );
        const array = data.split('\n');
        const mapList = array.map((column: string) => {
            const obj: any = {};
            const list = column.split(',');
            for (let i = 0; i < list.length; i++) {
                obj[keys[i]] = `${list[i]}`.replace('\r', '');
            }
            return obj;
        });
        this.post('/ssn/pertesterList', { data: { list: mapList } })
            .then((res: any) => {
                ElMessage({ message: 'Success', type: 'success' });
            })
            .catch((err: any) => {
                console.log(err);
                ElMessage({ message: '插入异常，请联系管理', type: 'error' });
            });
    };
}
