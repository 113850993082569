import axios from 'axios';
import { ElMessage } from 'element-plus';
import { inject } from 'vue';
export default class Http {
    protected loading: any;
    private service = axios.create();
    protected mitter: any = inject('mitter');
    constructor() {
        this.init();
    }
    public init() {
        axios.defaults.timeout = 2000 * 1000;
        axios.defaults.withCredentials = true;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.service.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
        this.service.interceptors.response.use(
            (response) => {
                return response.data;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }
    public get = (url: string, option = {}) => {
        return this.ajax(url, option);
    };
    public post = (url: string, option = {}) => {
        return this.ajax(url, Object.assign(option, { method: 'post' }));
    };
    private ajax(url: string, option: any) {
        return new Promise((resolve, reject) => {
            if (location.port === '8080') {
                option.baseURL = '/apis';
            }
            this.service(url, option)
                .then((res: any) => {
                    if (res.code === '-1000') {
                        ElMessage({ message: res.message, type: 'error' });
                        reject(res);
                    } else {
                        resolve(res);
                    }
                })
                .catch(reject);
        });
    }
}
